import {Button, Link, Typography} from 'components'
import {
  EVENT_CHAINING_ACTION_TO_ANALYTICS_NAME,
  eventTypeToDisplayName,
} from 'app/enums'
import styles, {mediaMinWidthSmall} from 'components/styles'
import {useContext, useState} from 'react'

import CurrentOrganizationContext from 'app/CurrentOrganizationContext'
import {EMPTY_STREET_ADDRESS_FIELDS_FOR_SIGNUP_REQUEST} from 'app/constants'
import {F} from 'util/i18n'
import TurnstileWidget from 'components/TurnstileWidget'
import analytics from 'analytics'
import api from 'data/api'
import {canUseOneTapSignup} from 'util/event'
import clientVars from 'util/clientVars'
import {css} from '@emotion/react'
import {formatMinimalMomentRange} from 'util/time'
import {getOrganizationEventUrl} from 'util/routing'
import moment from 'vendor/moment'
import styled from '@emotion/styled/macro'
import {useSignupButtonLabel} from 'events/details/SignupForm/util'

// TODO(jared): this row/button structure is duplicated in PromotionSuggestionsModalStepper. If we
// use it in a third place, it's probably time to formalize it as a top-level component

const TimeslotRow = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: ${styles.space.m};
  border-top: 1px solid ${styles.colors.neutral300};
  &:first-of-type {
    border-top: none;
    margin-top: ${styles.space.m};
  }
`

const ButtonWrapper = styled.div`
  width: 8rem;
  min-width: 8rem; // Prevent flexbox from crushing button
  ${mediaMinWidthSmall(css`
    width: auto;
    min-width: 10rem;
  `)}
  margin-left: ${styles.space.m};
`

const TimeslotDetails = styled.div`
  color: ${styles.colors.neutral400};
  overflow-wrap: anywhere;
`

function maybeGetLocationLine({
  is_virtual,
  location_name,
  address_line1,
  city,
  state,
  country,
  zipcode,
}) {
  if (is_virtual) {
    return 'Virtual event · Join from anywhere'
  }
  const stateOrCountry = state || country
  const preferenceOrder = [
    location_name,
    address_line1,
    city && stateOrCountry && `${city}, ${stateOrCountry}`,
    city,
    zipcode,
  ]
  return preferenceOrder.find(Boolean) || null
}

function getFormattedDateAndTime(timeslot) {
  return formatMinimalMomentRange(
    moment(timeslot.starts_at_utc, moment.ISO_8601),
    moment(timeslot.ends_at_utc, moment.ISO_8601),
    // Only include the timezone for in-person events (formatCompressedMomentRange only displays it
    // if it's not the current browser timezone)
    {timezone: !timeslot.event.is_virtual ? timeslot.event.timezone : null}
  )
}

function trackRegistrationCreated(
  action,
  signupRequest,
  currentOrganization,
  timeslot
) {
  analytics.trackRegistrationCreated(currentOrganization, {
    ...signupRequest,
    virtual: timeslot.event.is_virtual,
    // virtualWhen is not applicable for now since you can only sign up for virtual shifted events
    // from this modal
    virtualWhen: null,
    referringVol: null,
    created_by_distributed_organizing:
      timeslot.event.created_by_distributed_organizing,
    eventType: eventTypeToDisplayName(
      timeslot.event.event_type,
      timeslot.event.is_virtual
    ),
    event_host_type: null, // TODO(jared) do we need this? if so would need to expand it
    numShifts: 1,
    prechecked_two_timeslots: null,
    referrer_share_context: null,
    referrer_share_medium: null,
    signup_source: 'followup_modal',
  })
}

export async function signUpForTimeslot({
  action,
  onSignup,
  organization,
  setSignedUpForTimeslotIds,
  signupIdentityFields,
  signedUpForTimeslotIds,
  timeslot,
  trackingParams,
  turnstileToken,
}) {
  // Prevent double signups
  if (signedUpForTimeslotIds.has(timeslot.id)) {
    return
  }

  const {firstName, lastName, phone, zip, email} = signupIdentityFields

  // Optimistically try to show that the signup succeeded
  const timeslotIds = [...signedUpForTimeslotIds, timeslot.id]
  setSignedUpForTimeslotIds(new Set(timeslotIds))

  if (onSignup) {
    onSignup(timeslotIds)
  }

  const data = {
    shifts: [{timeslot_id: timeslot.id}],
    affiliation_id: organization.id,
    firstName,
    lastName,
    phone,
    zip,
    email,
    // N.B. Use empty values for street address until they are supported here.
    ...EMPTY_STREET_ADDRESS_FIELDS_FOR_SIGNUP_REQUEST,
    createdFromDashboard: false,
    referring_user_id: null,
    smsOptIn: false,
    is_promoted: !timeslot.event.current_org_is_owner_or_co_owner,
    ...trackingParams,
    followup_modal_context: EVENT_CHAINING_ACTION_TO_ANALYTICS_NAME[action],
    // This duplicates the value of followup_modal_context into a variable to store
    // on the Participation
    event_suggestion_context: EVENT_CHAINING_ACTION_TO_ANALYTICS_NAME[action],
    cf_turnstile_token: turnstileToken,
  }

  let notBlocked = true
  try {
    const resp = await api.signUpByEventId(timeslot.event.id, data)
    notBlocked = !resp.user_is_blocked
  } catch (e) {
    // TODO(jared) display error state in addition to reverting the optimistic update
    setSignedUpForTimeslotIds(
      new Set([...signedUpForTimeslotIds].filter((id) => id !== timeslot.id))
    )
    return
  }

  notBlocked &&
    organization &&
    trackRegistrationCreated(action, data, organization, timeslot)
}

// exported for testing
const EventChainingTimeslotRow = ({
  action,
  onSignup,
  signedUpForTimeslotIds,
  signupIdentityFields,
  setSignedUpForTimeslotIds,
  timeslot,
  trackingParams,
}) => {
  const {currentOrganization} = useContext(CurrentOrganizationContext)
  const [showTurnstile, setShowTurnstile] = useState(false)
  const event = timeslot.event
  const showOneTapSignup = canUseOneTapSignup(event, signupIdentityFields)

  const locationLine = maybeGetLocationLine(event)
  const signedUpForThisTimeslot = signedUpForTimeslotIds.has(timeslot.id)
  const timeslotDetailsUrl =
    currentOrganization &&
    getOrganizationEventUrl(currentOrganization, timeslot.event, {
      timeslot: timeslot.id,
    })

  const signupButtonText = useSignupButtonLabel({
    event,
    isFeed: false,
    signedUp: signedUpForThisTimeslot,
    signupIdentityFields,
  })

  function attemptSignup(turnstileToken) {
    if (clientVars.turnstile_enabled) {
      if (turnstileToken === undefined) {
        setShowTurnstile(true)
        return
      } else {
        setShowTurnstile(false)
      }
    }

    if (!currentOrganization) {
      return
    }
    signUpForTimeslot({
      action,
      onSignup,
      organization: currentOrganization,
      setSignedUpForTimeslotIds,
      signupIdentityFields,
      signedUpForTimeslotIds,
      timeslot,
      trackingParams,
      turnstileToken,
    })
  }

  return (
    <TimeslotRow key={timeslot.id}>
      <Link plain noUnderline to={timeslotDetailsUrl}>
        <div>
          <Typography variant="h3">{timeslot.event.name}</Typography>
          <TimeslotDetails>
            <div>{getFormattedDateAndTime(timeslot)}</div>
            {locationLine && <div>{locationLine}</div>}
            {currentOrganization?.id !== timeslot.event.organization.id && (
              <div data-testid="eventchainingtimeslot-promoted">
                <F
                  defaultMessage="Hosted by {orgName}"
                  values={{orgName: timeslot.event.organization.name}}
                />
              </div>
            )}
          </TimeslotDetails>
        </div>
      </Link>
      <ButtonWrapper>
        {!showOneTapSignup ? (
          <Link target="_blank" to={timeslotDetailsUrl} plain>
            <Button data-testid="eventchainingtimeslot-details" fluid nowrap>
              See details
            </Button>
          </Link>
        ) : showTurnstile ? (
          <TurnstileWidget
            action="eventchainingtimeslot-signup"
            onVerify={attemptSignup}
            compact
          />
        ) : (
          <Button
            data-testid="eventchainingtimeslot-signup"
            fluid
            onClick={() => attemptSignup()}
            icon={signedUpForThisTimeslot ? 'check' : undefined}
            iconPosition="left-inline"
            success={signedUpForThisTimeslot}
          >
            {signupButtonText}
          </Button>
        )}
      </ButtonWrapper>
    </TimeslotRow>
  )
}

export default EventChainingTimeslotRow
