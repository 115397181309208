import {
  Button,
  FacebookLoginButton,
  Form,
  GoogleLoginButton,
  Input,
  MailboxImage,
  Message,
  Typography,
} from 'components'
import {F, defineMessages, useIntl} from 'util/i18n'
import {useEffect, useMemo, useState} from 'react'
import {useLocation, withRouter} from 'react-router'

import DefaultThemeProvider from 'app/DefaultThemeProvider'
import {GoogleLoginButtonOptions} from '../enums'
import {LoginMethod} from 'app/enums'
import analytics from 'analytics'
import api from 'data/api'
import cv from 'util/clientVars'
import {getQueryParams} from 'util/url'
import {showGoogleLogin} from 'util/user'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const LoginComponentFlowState = {
  CHOOSE_LOGIN_METHOD: 1,
  EMAIL_CHOSEN: 2,
  EMAIL_SENT: 3,
}

const TopPaddedP = styled.p`
  padding-top: ${styles.space.m};
`

const CenteredDiv = styled.div`
  text-align: center;
`

const TopPaddedDiv = styled.div`
  padding-top: ${styles.space.m};
`

const GoogleLoginButtonWrapper = styled.div`
  padding-bottom: ${styles.space.s};
`

const NoWrapSpan = styled.span`
  white-space: nowrap;
`

const CardHeader = styled.div`
  padding-bottom: ${styles.space.m};
`

const EmailSentText = styled.div`
  font-size: ${styles.typography.fontSizeL};
  word-wrap: break-word;
`

const ErrorMessageWrapper = styled.div`
  padding-top: ${styles.space.s};
  padding-bottom: ${styles.space.s};
  text-align: center;
`

const i18nMessages = defineMessages({
  loginSendFailed: {defaultMessage: 'Could not send login link'},
})

/**
 * Universal login modal.
 */
function LoginComponent({
  onGoogleConnected,
  onFacebookConnected,
  headerText,
  email: initialEmail,
}) {
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [loginComponentFlowState, setLoginComponentFlowState] = useState(
    LoginComponentFlowState.CHOOSE_LOGIN_METHOD
  )
  const [email, setEmail] = useState(initialEmail)
  const [getLinkButtonDisabled, setGetLinkButtonDisabled] = useState(false)
  const location = useLocation()
  const intl = useIntl()

  const queryParams = useMemo(() => getQueryParams(location.search), [
    location.search,
  ])

  const onFormSubmit = async (e) => {
    e.preventDefault()
    // Disable the link button until the async call returns,
    // so that the user will not request multiple tokens
    setGetLinkButtonDisabled(true)
    analytics.trackLoginRequested({
      email,
      login_method: LoginMethod.MAGIC_LINK,
    })
    await requestLoginLink()
  }

  const extractAhaRedirect = () => {
    return queryParams.from === 'aha'
  }

  const loginType = () => {
    const fromAha = extractAhaRedirect()
    return fromAha
      ? GoogleLoginButtonOptions.LOGIN_WITH_AHA
      : GoogleLoginButtonOptions.LOGIN
  }

  const requestLoginLink = async () => {
    try {
      let maybeNext = queryParams.next
      const maybeEmail = email || ''
      if (extractAhaRedirect()) {
        maybeNext = 'aha'
      }
      // If we have a "next" param, pass it to the requested OTT - otherwise, stick with the current pathname.
      await api.requestLoginLink(maybeEmail, maybeNext || location.pathname)
      setLoginComponentFlowState(LoginComponentFlowState.EMAIL_SENT)
    } catch (e) {
      setErrorMessage(
        (e && e.message) || intl.formatMessage(i18nMessages.loginSendFailed)
      )
      setShowError(true)
      setGetLinkButtonDisabled(false)
    }
  }

  useEffect(() => {
    if (queryParams.pasted) {
      setShowError(true)
    }
  }, [queryParams.pasted])

  return (
    <DefaultThemeProvider>
      {showError && (
        <ErrorMessageWrapper>
          <Message
            type="error"
            header={<F defaultMessage="Login request failed" />}
          >
            {errorMessage && (
              <>
                {errorMessage}
                <br />
              </>
            )}
            <F
              defaultMessage="Try again below, or <a>contact Mobilize support</a> for help."
              values={{
                a: (msg) => <a href="https://mblz.io/get-help">{msg}</a>,
              }}
            />
          </Message>
        </ErrorMessageWrapper>
      )}
      {loginComponentFlowState ===
        LoginComponentFlowState.CHOOSE_LOGIN_METHOD && (
        <CenteredDiv>
          <CardHeader>
            <Typography variant="h4" center>
              {headerText || (
                <F
                  defaultMessage="Log in to {product_name}"
                  values={{product_name: cv.product_name}}
                />
              )}
            </Typography>
          </CardHeader>
          {showGoogleLogin() && (
            <GoogleLoginButtonWrapper>
              <GoogleLoginButton
                onError={({message}) => {
                  setErrorMessage(message)
                  setShowError(true)
                }}
                onComplete={onGoogleConnected}
                fluid
                type={loginType()}
              />
            </GoogleLoginButtonWrapper>
          )}
          <FacebookLoginButton
            type={loginType()}
            onComplete={onFacebookConnected}
            onError={({message}) => {
              setErrorMessage(message)
              setShowError(true)
            }}
            fluid
          />
          <TopPaddedP>
            <F defaultMessage="or" />
          </TopPaddedP>
          <Button
            fluid
            secondary
            onClick={() => {
              setLoginComponentFlowState(LoginComponentFlowState.EMAIL_CHOSEN)
              setErrorMessage(null)
              setShowError(false)
            }}
          >
            <F defaultMessage="Get a link and code" />
          </Button>
        </CenteredDiv>
      )}
      {loginComponentFlowState === LoginComponentFlowState.EMAIL_CHOSEN && (
        <CenteredDiv>
          <CardHeader variant="h4">
            <F defaultMessage="Log in via a link and code sent to your email" />
          </CardHeader>
          {/* This form is used only to enable HTML5 form validation on the input */}
          <Form onSubmit={onFormSubmit}>
            <Input
              type="email"
              name="email"
              onChange={(evt) => setEmail(evt.currentTarget.value)}
              value={email || ''}
              label={<F defaultMessage="Email" />}
              fluid
              required
            />
            <TopPaddedDiv>
              <Button fluid disabled={getLinkButtonDisabled}>
                <NoWrapSpan>
                  <F defaultMessage="Send link and code" />
                </NoWrapSpan>
              </Button>
            </TopPaddedDiv>
          </Form>
        </CenteredDiv>
      )}
      {loginComponentFlowState === LoginComponentFlowState.EMAIL_SENT && (
        <CenteredDiv>
          <Typography variant="h1">
            <F defaultMessage="Sent!" />{' '}
          </Typography>
          <MailboxImage />
          <EmailSentText>
            <F
              defaultMessage="If your user account exists, you will receive an email at <strong>{email}</strong>"
              values={{
                strong: (msg) => <strong>{msg}</strong>,
                email,
              }}
            />
          </EmailSentText>
        </CenteredDiv>
      )}
    </DefaultThemeProvider>
  )
}

export default withRouter(LoginComponent)
