import {F, defineMessages} from 'util/i18n'
import React, {useCallback, useEffect, useRef, useState} from 'react'

import DefaultThemeProvider from 'app/DefaultThemeProvider'
import FacebookButton from './FacebookButton'
import {FacebookLoginMessageType} from 'app/enums'
import api from 'data/api'
import callAhaEndpoint from 'dashboard/components/util'
import {getEmbeddedFacebookLoginUrl} from 'util/routing'
import styled from '@emotion/styled/macro'
import {useIntl} from 'react-intl'

const LoginIframe = styled.iframe`
  border: none;
  width: 100%;
  height: 52px; // TODO: figure out resizing
`

const Wrapper = styled.div`
  position: relative;
`

const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
`

const shouldUseIframe = () => !!window.__MLZ_IS_CUSTOM_DOMAIN__

const i18nMessages = defineMessages({
  loading: {
    defaultMessage: 'The page is still loading. Try clicking again in a bit.',
  },
})

let counter = 0

const FacebookLoginButton = (props) => {
  const [frameLoading, setFrameLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const intl = useIntl()
  const id = useRef(counter.toString())
  counter += 1

  const handleApiError = useCallback(
    (e) => {
      let detailedError
      if (e.json) {
        detailedError =
          typeof e.json.error === 'string' ? e.json.error : e.json.error.message
      }
      if (typeof props.onError === 'function') {
        props.onError(e, detailedError)
      }
    },
    [props]
  )

  const onMessage = useCallback(
    async (event) => {
      const {origin, data} = event
      const {onClick, onError, onComplete} = props
      if (
        // Skip this message if it's from the wrong origin
        origin !== new URL(window.__MLZ_SERVER_URL__).origin ||
        !data ||
        // Skip the message if it isn't from the right id (anotherFacebookLoginButton will probably
        // handle it in this case)
        data.id !== id.current ||
        // Skip the message if it doesn't have a type
        !data.type
      ) {
        return
      }
      switch (data.type) {
        case FacebookLoginMessageType.INITIATED:
          if (typeof onClick === 'function') {
            onClick()
          }
          break
        case FacebookLoginMessageType.ERROR:
          if (typeof onError === 'function') {
            onError(data.data)
          }
          break
        case FacebookLoginMessageType.SUCCESS:
          const {ott, user_id} = data.data
          let userDetails
          try {
            userDetails = await api.loginWithToken(user_id, ott)
          } catch (e) {
            if (typeof onError === 'function') {
              handleApiError(e)
            }
            break
          }
          onComplete(userDetails)
          break
        default:
          break // no-op, this is to appease eslint
      }
    },
    [handleApiError, props]
  )

  const onClick = (evt) => {
    const {onClick, onComplete, onError, type} = props
    // Prevent from scrolling to the top of the page
    evt.preventDefault()
    setLoading(true)

    if (typeof onClick === 'function') {
      onClick()
    }
    try {
      window.FB.login(
        async (response) => {
          const {authResponse, status} = response
          if (status === 'connected') {
            const apiMethod = ['login', 'aha'].includes(type)
              ? api.loginWithFacebook
              : api.connectUserFacebook
            try {
              const data = await apiMethod(
                authResponse.userID,
                authResponse.accessToken
              )
              if (type === 'aha') {
                await callAhaEndpoint(data.user.id)
              }
              setLoading(false)
              onComplete(data)
            } catch (e) {
              setLoading(false)
              if (typeof onError === 'function') {
                handleApiError(e)
              }
            }
          } else {
            // NB: when the user has cancelled the FB login, status comes back as 'unknown'.
            // But here, if we get any outcome other than 'connected', return the FB button
            // to its active state.
            setLoading(false)
          }
        },
        {scope: 'public_profile,email'}
      )
    } catch (ex) {
      setLoading(false)
      if (typeof onError === 'function') {
        onError(
          window.FB ? ex : new Error(intl.formatMessage(i18nMessages.loading))
        )
      }
    }
  }

  useEffect(() => {
    if (shouldUseIframe()) {
      window.addEventListener('message', onMessage)
      return () => {
        window.removeEventListener('message', onMessage)
      }
    }
  }, [onMessage])

  const {type, fluid} = props

  return (
    <DefaultThemeProvider>
      {shouldUseIframe() ? (
        <Wrapper>
          {frameLoading && (
            <LoaderWrapper>
              <FacebookButton fluid disabled />
            </LoaderWrapper>
          )}
          <LoginIframe
            title="Continue with Facebook"
            src={getEmbeddedFacebookLoginUrl(id.current).toString()}
            onLoad={() => setFrameLoading(false)}
            data-id={id.current}
          />
        </Wrapper>
      ) : (
        <FacebookButton loading={loading} fluid={fluid} onClick={onClick}>
          {type === 'login' ? (
            <F defaultMessage="Continue with Facebook" />
          ) : (
            <F defaultMessage="Link Facebook account" />
          )}
        </FacebookButton>
      )}
    </DefaultThemeProvider>
  )
}

FacebookLoginButton.defaultProps = {
  type: 'login',
}

export default FacebookLoginButton
