import {memoize, sample} from 'util/common'

import {F} from '../../util/i18n'
import {FeedItem} from 'events/components'
import {getOrganizationDefaultEventCampaignCreateEventUrl} from 'util/routing'

// Memoize this so as to not flash different images on re-renders
const memoizedSample = memoize(sample)

const HostEventFeedItem = ({organization, eventCampaign}) => (
  <FeedItem
    linkTo={getOrganizationDefaultEventCampaignCreateEventUrl(organization)}
    headline={<F defaultMessage="Host an event!" />}
    aria-label={<F defaultMessage="Host an event" />}
    subhead={<F defaultMessage="Pick a location near you" />}
    buttonText={<F defaultMessage="Get started" />}
    imageUrl={memoizedSample(eventCampaign.hosted_event_images)}
  />
)

export default HostEventFeedItem
