import {
  Badge,
  Button,
  Divider,
  Image,
  InputGroup,
  Link,
  Typography,
} from 'components'
import {getEventImageAltText, getEventTypeAffinityChecks} from 'util/event'
import styles, {
  boxShadowLight,
  clampLines,
  getLinkStyleFromProps,
  mediaMinWidthDashboardLarge,
  mediaMinWidthSmall,
} from 'components/styles'

import CurrentOrganizationContext from 'app/CurrentOrganizationContext'
import DonationProgress from 'events/details/FundraisingForm/DonationProgress'
import {F} from 'util/i18n'
import Icon from 'components/Icon'
import SupporterProgress from 'events/details/SignupForm/SupporterProgress'
import {css} from '@emotion/react'
import {eventTypeToDisplayMessage} from 'app/enumMessages'
import moment from 'vendor/moment'
import {orgFlagIsActive} from 'util/organization'
import styled from '@emotion/styled/macro'
import {useContext} from 'react'
import {useExperimentAllocation} from 'hooks/experiments'

const ButtonContent = styled.div`
  min-width: 6.25rem;
`

const FeedItemDetail = styled.div`
  line-height: ${styles.typography.lineHeightM};
`

const TitleWrapper = styled.div`
  margin-bottom: ${(props) =>
    props.isCompactFeedItem ? styles.space.xs : styles.space.s};
  ${(props) => !props.isCompactFeedItem && `margin-bottom: ${styles.space.s};`}
  ${(props) => props.topMargin && `margin-top: ${styles.space.xs};`}

  & .mbz-typography-inner-wrapper {
    ${clampLines(2)}
  }
`

const BadgesWrapper = styled.div`
  padding-bottom: ${styles.space.xs};
`

const FeedItemSubhead = styled.div`
  ${(props) =>
    !props.isCompactFeedItem &&
    css`
      margin-top: ${styles.space.xs};
      margin-bottom: ${styles.space.s};
    `}
`

const DetailsContainer = styled.div`
  margin-top: ${styles.space.xs};
  margin-bottom: ${styles.space.xs};
`

const SeeMoreItemWrapper = styled.div`
  padding: ${styles.space.s} ${styles.space.m} ${styles.space.s}
    ${styles.space.m};
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  ${Button},
  ${Link} {
    width: 100%;
  }
`

const SeeMoreItemHeaderWrapper = styled.div`
  margin-bottom: ${styles.space.l};
`

const Body = styled.div`
  padding: ${(props) => (props.showCompactDivider ? 0 : styles.space.s)}
    ${styles.space.m} ${styles.space.s} ${styles.space.m};
  display: flex;
  flex-direction: column;
  flex: auto;
  justify-content: space-between;

  ${Button},
  ${Link} {
    width: 100%;
  }

  ${Button}:not(:last-child),
  ${Link}:not(:last-child) {
    margin-right: 0;
  }

  ${(props) =>
    !props.isCompactFeedItem &&
    mediaMinWidthSmall(css`
      ${Button},
      ${Link} {
        width: auto;
      }

      ${Button}:not(:last-child),
          ${Link}:not(:last-child) {
        margin-right: ${styles.space.m};
      }
    `)}
`

const ImageTagWrapper = styled.div`
  position: absolute;
  top: ${styles.space.l};
  left: ${styles.space.l};
  z-index: ${styles.zindex.abovePage};
`

const IMAGE_WIDTH_LG = 338

const ImageWrapper = styled.div`
  position: relative;
  flex: 0;
  width: 100%;
  align-self: center;

  ${(props) =>
    props.imageIsLogo &&
    css`
      padding: ${styles.space.s};
      padding-bottom: 0px;
    `}

  ${(props) =>
    !props.isCompactFeedItem &&
    mediaMinWidthSmall(css`
      width: ${IMAGE_WIDTH_LG}px;
      min-width: ${IMAGE_WIDTH_LG}px;
      padding: ${styles.space.m};
    `)}
`

const TimeWrapper = styled.time`
  display: inline-block;
  // Bit of a hack to get these to display like links for now. We might want to eventually introduce
  // some more granular control
  ${(props) =>
    !props.isCompactFeedItem &&
    getLinkStyleFromProps(props, {
      disableHover: true,
      disableTextDecoration: true,
      disableLinkColor: true,
    })}
  ${(props) => props.blue && `color: ${styles.colors.primary200};`}
  font-weight: ${styles.typography.fontWeightBold};

  &::first-letter {
    text-transform: capitalize;
  }
`

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  ${(props) =>
    !props.isCompactFeedItem && mediaMinWidthSmall(`flex-direction: row;`)}
`

const ItemContent = styled.div`
  flex: auto;
`

const EyebrowWrapper = styled.span`
  align-items: center;
  display: flex;

  span:nth-of-type(2) {
    ${(props) => !props.isCompactFeedItem && `margin-top: 4px`}
  }
  margin-top: 5px;
`

const ActionContent = styled.div`
  margin-top: ${styles.space.m};
`

const commonItemCSS = css`
  display: block;
  background-color: ${styles.colors.white};
  margin: 0 0 ${styles.space.s};
  overflow: hidden;
  color: ${styles.colors.black};
  ${boxShadowLight}
  ${mediaMinWidthDashboardLarge(`margin-bottom: ${styles.space.m};`)};
`

const ItemLinkWrapper = styled(Link)`
  ${commonItemCSS}

  &:hover,
  &:focus {
    text-decoration: none;
    color: ${styles.colors.black};
  }
`
const CenterHeadlineWrapper = styled.div`
  text-align: center;
  padding-top: ${styles.typography.fontSizeXS};
  padding-bottom: ${styles.typography.fontSizeXS};
`
const ItemWrapper = styled.div`
  ${commonItemCSS}
`
const IconWrapper = styled.div`
  vertical-align: middle;
  padding-top: 1.5rem;
  padding-bottom: 0.375rem;
  text-align: center;
  position: relative;
  flex: 0;
`

export const getTagForUpcomingEvents = (event) => {
  if (event?.is_virtual_flexible || !event?.times[0].start) {
    return null
  }
  const eventDate = moment(event?.times[0].start)

  const today = moment()
  const tomorrow = moment().add(1, 'days')

  const startOfWeekend = moment().isoWeekday(6)
  const endOfWeekend = moment().isoWeekday(7)

  if (eventDate.isSame(today, 'day')) {
    return (
      <Badge bgColor="primary100" marginRight>
        <F defaultMessage="Today" />
      </Badge>
    )
  }

  if (eventDate.isSame(tomorrow, 'day')) {
    return (
      <Badge bgColor="primary100" marginRight>
        <F defaultMessage="Tomorrow" />
      </Badge>
    )
  }

  if (
    eventDate.isSameOrAfter(startOfWeekend, 'day') &&
    eventDate.isSameOrBefore(endOfWeekend, 'day')
  ) {
    return (
      <Badge bgColor="primary100" marginRight>
        <F defaultMessage="This weekend" />
      </Badge>
    )
  }

  if (eventDate.isSame(today, 'week') && eventDate.isBefore(startOfWeekend)) {
    return (
      <Badge bgColor="primary100" marginRight>
        <F defaultMessage="This week" />
      </Badge>
    )
  }

  return null
}

/** A slightly jank abstraction of a feed item. Eventually should be consolidated with `Card`. */
const FeedItem = (props) => {
  const {
    actionContent,
    buttonText,
    details,
    dontLinkButtons,
    event,
    firstAvailableISO,
    headline,
    imageIsLogo,
    imageSrcSet,
    imageTag,
    imageUrl,
    isCompactFeedItem,
    isSeeMoreItem,
    linkState,
    linkTarget,
    linkTo,
    secondaryDetails,
    showCompactDivider,
    subhead,
    time,
    iconName,
    centerHeadline,
  } = props

  const {currentOrganization} = useContext(CurrentOrganizationContext)
  const linkProps = linkTo
    ? {
        plain: true,
        to: linkTo,
        state: linkState,
        trackingName: 'Event',
        'aria-label': headline,
        target: linkTarget,
      }
    : null

  const {isRegistrationOnly, isDonationCampaign} = getEventTypeAffinityChecks(
    event
  )
  const showSupporterProgress =
    isRegistrationOnly && event && !event.disable_participant_count
  const showDonationProgress =
    isDonationCampaign && event && !event.disable_participant_goal

  const subheading =
    isRegistrationOnly && event ? (
      <>
        <F {...eventTypeToDisplayMessage(event.event_type, false)} />
        {!!subhead ? <> {subhead}</> : null}
      </>
    ) : (
      subhead
    )

  function maybeMakeLink(content, additionalLinkProps) {
    if (dontLinkButtons && linkProps) {
      const combinedProps = {
        ...linkProps,
        ...additionalLinkProps,
      }
      return <Link {...combinedProps}>{content}</Link>
    }
    return content
  }

  const hasNewEvents = (event) => {
    const threeDaysAgo = moment().subtract(3, 'days')

    let hasNew = false
    event?.times.forEach((time) => {
      if (moment(time.created_at).isSameOrAfter(threeDaysAgo)) {
        hasNew = true
        return
      }
    })

    return hasNew
  }

  const shouldShowNewTag =
    orgFlagIsActive(currentOrganization, 'enable_new_event_tag') &&
    hasNewEvents(event)

  const upcomingTag = getTagForUpcomingEvents(event)

  const newTag = shouldShowNewTag ? (
    <Badge textColor="white" bgColor="primary200" marginRight>
      <F defaultMessage="New!" />
    </Badge>
  ) : (
    ''
  )

  const itemContent = (
    <ItemContent>
      {newTag} {upcomingTag}
      {showDonationProgress
        ? event && (
            <EyebrowWrapper isCompactFeedItem={!!isCompactFeedItem}>
              <DonationProgress
                event={event}
                expanded={false}
                isCompactFeedItem={isCompactFeedItem}
              />
            </EyebrowWrapper>
          )
        : showSupporterProgress
        ? event && (
            <EyebrowWrapper isCompactFeedItem={!!isCompactFeedItem}>
              <SupporterProgress
                event={event}
                isCompactFeedItem={isCompactFeedItem}
              />
            </EyebrowWrapper>
          )
        : time && (
            <EyebrowWrapper isCompactFeedItem={!!isCompactFeedItem}>
              <Typography
                variant={isCompactFeedItem ? 'subtitle1' : 'body2'}
                component="span"
              >
                <TimeWrapper
                  dateTime={firstAvailableISO}
                  isCompactFeedItem={!!isCompactFeedItem}
                >
                  {time}
                </TimeWrapper>
              </Typography>
            </EyebrowWrapper>
          )}
      {headline && (
        <TitleWrapper isCompactFeedItem={!!isCompactFeedItem}>
          {centerHeadline ? (
            <CenterHeadlineWrapper>
              <Typography variant={isCompactFeedItem ? 'h4' : 'h2'}>
                {headline}
              </Typography>
            </CenterHeadlineWrapper>
          ) : (
            <Typography variant={isCompactFeedItem ? 'h4' : 'h2'}>
              {headline}
            </Typography>
          )}
        </TitleWrapper>
      )}
      <Typography
        variant={isCompactFeedItem ? 'body2' : 'body1'}
        component="div"
      >
        {subheading && (
          <FeedItemSubhead isCompactFeedItem={!!isCompactFeedItem}>
            {subheading}
          </FeedItemSubhead>
        )}
        <DetailsContainer>
          {Array.isArray(details) &&
            details.map((detail, i) => (
              // TODO(jared) figure out better key for arbitrary Nodes
              <FeedItemDetail key={i}>{detail}</FeedItemDetail>
            ))}
        </DetailsContainer>
      </Typography>
      {secondaryDetails}
    </ItemContent>
  )

  const shouldUseExperimentalItemContentWithEventTypeTag =
    useExperimentAllocation('feed_event_type_tag')?.variant.name ===
      'event_type_tag' &&
    orgFlagIsActive(currentOrganization, 'enable_feed_event_type_tags')

  const experimentalItemContentWithEventTypeTag = (
    <ItemContent>
      {showDonationProgress
        ? event && (
            <EyebrowWrapper isCompactFeedItem={!!isCompactFeedItem}>
              <DonationProgress
                event={event}
                expanded={false}
                isCompactFeedItem={isCompactFeedItem}
              />
            </EyebrowWrapper>
          )
        : showSupporterProgress
        ? event && (
            <EyebrowWrapper isCompactFeedItem={!!isCompactFeedItem}>
              <SupporterProgress
                event={event}
                isCompactFeedItem={isCompactFeedItem}
              />
            </EyebrowWrapper>
          )
        : time && (
            <EyebrowWrapper isCompactFeedItem={!!isCompactFeedItem}>
              <Typography
                variant={isCompactFeedItem ? 'subtitle1' : 'body2'}
                component="span"
              >
                <TimeWrapper
                  dateTime={firstAvailableISO}
                  isCompactFeedItem={!!isCompactFeedItem}
                  blue
                >
                  {time}
                </TimeWrapper>
              </Typography>
            </EyebrowWrapper>
          )}
      {headline && (
        <TitleWrapper isCompactFeedItem={!!isCompactFeedItem}>
          {centerHeadline ? (
            <CenterHeadlineWrapper>
              <Typography variant={isCompactFeedItem ? 'h4' : 'h2'}>
                {headline}
              </Typography>
            </CenterHeadlineWrapper>
          ) : (
            <Typography variant={isCompactFeedItem ? 'h4' : 'h2'}>
              {headline}
            </Typography>
          )}
        </TitleWrapper>
      )}

      <BadgesWrapper>
        {newTag}
        {upcomingTag}
        {event?.event_type && (
          <Badge textColor="black" bgColor="neutral200" marginRight>
            <F {...eventTypeToDisplayMessage(event?.event_type)} />
          </Badge>
        )}
      </BadgesWrapper>

      <Typography
        variant={isCompactFeedItem ? 'body2' : 'body1'}
        component="div"
      >
        {subheading && (
          <FeedItemSubhead isCompactFeedItem={!!isCompactFeedItem}>
            {subheading}
          </FeedItemSubhead>
        )}
        <DetailsContainer>
          {Array.isArray(details) &&
            details.map((detail, i) => (
              // TODO(jared) figure out better key for arbitrary Nodes
              <FeedItemDetail key={i}>{detail}</FeedItemDetail>
            ))}
        </DetailsContainer>
      </Typography>
      {secondaryDetails}
    </ItemContent>
  )

  const imageContent = imageUrl ? (
    <ImageWrapper
      isCompactFeedItem={!!isCompactFeedItem}
      imageIsLogo={imageIsLogo}
    >
      {/** TODO: width here has been doubled for retina displays, do a media query or source
       * set or something */}
      <Image
        src={imageUrl}
        srcSet={imageSrcSet}
        maxWidth={IMAGE_WIDTH_LG * 2}
        alt={event ? getEventImageAltText(event) : headline}
        resizeFitMode={imageIsLogo ? 'contain' : 'cover'}
      />
    </ImageWrapper>
  ) : iconName ? (
    <IconWrapper>
      <Icon
        name={iconName}
        primary={true}
        noMargin={true}
        fontSize={styles.typography.largeIcon}
      />
    </IconWrapper>
  ) : null

  const content = (
    <>
      {/* TODO(mime): these className's are still being used by the EventsCarousel. */}
      <ItemContainer
        isCompactFeedItem={!!isCompactFeedItem}
        className="mbz-feed-item-container"
      >
        <Body
          isCompactFeedItem={!!isCompactFeedItem}
          showCompactDivider={showCompactDivider}
        >
          {showCompactDivider && <Divider />}
          {shouldUseExperimentalItemContentWithEventTypeTag
            ? maybeMakeLink(experimentalItemContentWithEventTypeTag, {
                'aria-label': props['aria-label'],
              })
            : maybeMakeLink(itemContent, {'aria-label': props['aria-label']})}
          {(buttonText || actionContent) && (
            <ActionContent>
              {buttonText && (
                <InputGroup>
                  <Button icon="arrow-right" tabIndex="-1">
                    <ButtonContent>{buttonText}</ButtonContent>
                  </Button>
                </InputGroup>
              )}
              {typeof actionContent === 'function' && actionContent()}
            </ActionContent>
          )}
        </Body>
        {/* todo: (hannah) there's probably a better way to determine whether we want the 'online event' tag to show than just determining whether the parent component has passed down copy. this component is annoyingly reused in some surprising ways and we actually only pass this prop down from the EventFeedItem */}
        {imageTag && (
          <ImageTagWrapper>
            <Badge bgColor="white" textColor="black">
              {imageTag}
            </Badge>
          </ImageTagWrapper>
        )}
        {maybeMakeLink(imageContent, {tabIndex: '-1'})}
      </ItemContainer>
    </>
  )

  // TODO(mime): these className's are still being used by the EventsCarousel.
  return linkProps && !dontLinkButtons ? (
    <ItemLinkWrapper
      {...linkProps}
      className="mbz-feed-item"
      aria-label={props['aria-label']}
    >
      {isSeeMoreItem ? (
        <SeeMoreItemWrapper>
          {headline && (
            <SeeMoreItemHeaderWrapper>
              <Typography variant="h2">{headline}</Typography>
            </SeeMoreItemHeaderWrapper>
          )}
          <Button tabIndex="-1" secondary>
            <ButtonContent>
              {buttonText || <F defaultMessage="See more" />}
            </ButtonContent>
          </Button>
        </SeeMoreItemWrapper>
      ) : (
        content
      )}
    </ItemLinkWrapper>
  ) : (
    <ItemWrapper className="mbz-feed-item">{content}</ItemWrapper>
  )
}

export default FeedItem
