import React, {useEffect} from 'react'

import {getDisplayName} from 'util/string'
import {pageview as googleAnalytics4PageView} from '../vendor/google-analytics-4'
import hoistNonReactStatics from 'hoist-non-react-statics'
import {pageview as pixelPageView} from '../vendor/fb-pixel'

export default function withOrgAnalytics(Wrapped) {
  function WithOrgAnalytics(props) {
    const {facebook_pixel_id, google_analytics_4_id} =
      props.data?.current_organization || {}

    useEffect(() => {
      if (facebook_pixel_id) {
        pixelPageView(facebook_pixel_id)
      }
    }, [facebook_pixel_id])

    useEffect(() => {
      if (google_analytics_4_id) {
        googleAnalytics4PageView(google_analytics_4_id)
      }
    }, [google_analytics_4_id])

    return <Wrapped {...props} />
  }

  WithOrgAnalytics.displayName = `WithOrgAnalytics(${getDisplayName(Wrapped)})`

  return hoistNonReactStatics(WithOrgAnalytics, Wrapped)
}
