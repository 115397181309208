import {
  EventChainingAction,
  ParticipationShortlinkSubtype,
  PostSignupAsk,
  eventTypeToDisplayName,
} from 'app/enums'
import {getOrganizationEventUrl, getSubtypedShortlink} from 'util/routing'
import {useContext, useState} from 'react'

import {Button} from 'components'
import ChainingModalStepper from 'events/modals/ChainingModalStepper'
import {EMPTY_STREET_ADDRESS_FIELDS_FOR_SIGNUP_REQUEST} from 'app/constants'
import {ModalTypes} from 'events/modals/enums'
import TrackingParamsContext from 'app/TrackingParamsContext'
import TurnstileWidget from 'components/TurnstileWidget'
import analytics from 'analytics'
import api from 'data/api'
import clientVars from 'util/clientVars'
import {formatIdentityFieldsForSignupRequest} from 'util/user'
import {useSignupButtonLabel} from 'events/details/SignupForm/util'

function trackRegistrationCreated(
  signupRequest,
  organization,
  event,
  signupSource
) {
  analytics.trackRegistrationCreated(organization, {
    ...signupRequest,
    virtual: event.is_virtual,
    // virtualWhen is not applicable for now since you can only sign up for virtual shifted events
    // from this modal
    virtualWhen: null,
    referringVol: null,
    created_by_distributed_organizing: event.created_by_distributed_organizing,
    eventType: eventTypeToDisplayName(event.event_type, event.is_virtual),
    event_host_type: null, // TODO(jared) do we need this? if so would need to expand it
    numShifts: 1,
    prechecked_two_timeslots: null,
    referrer_share_context: null,
    referrer_share_medium: null,
    signup_source: signupSource,
  })
}

export default function SignupButton({
  event,
  eventSuggestionContext,
  timeslot,
  currentOrganization,
  signupIdentityFields,
  centered,
  inversePrimaryColors,
  signupSource = 'feed_one_tap',
  showSignupModal = true,
  useEventOrganization = false,
}) {
  const {trackingParams} = useContext(TrackingParamsContext)
  const [signupResponse, setSignupResponse] = useState(null)
  const [signupButtonLoading, setSignupButtonLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [showTurnstile, setShowTurnstile] = useState(false)
  const signedUp = !!signupResponse
  const timeslotId = timeslot ? timeslot.id : event.times[0]?.id
  const organization = useEventOrganization
    ? event.organization
    : currentOrganization
  const signupButtonLabel = useSignupButtonLabel({
    timeslot,
    event,
    signedUp,
    signupIdentityFields,
    isFeed: true,
  })

  async function attemptSignup(turnstileToken) {
    if (clientVars.turnstile_enabled) {
      if (turnstileToken === undefined) {
        setShowTurnstile(true)
        return
      } else {
        setShowTurnstile(false)
      }
    }

    // Prevent multiple signups
    if (signedUp || signupButtonLoading) return

    setSignupButtonLoading(true)

    const signupRequest = {
      affiliation_id: organization.id,
      shifts: [{timeslot_id: timeslotId}],
      referring_user_id: null,
      sharer_role: null,
      smsOptIn: false,
      createdFromDashboard: false,
      is_promoted: !event.current_org_is_owner_or_co_owner,
      event_suggestion_context:
        eventSuggestionContext || trackingParams.followup_modal_context,
      cf_turnstile_token: turnstileToken,
      ...formatIdentityFieldsForSignupRequest(signupIdentityFields),
      // N.B. These address fields are required in SignupRequest, but are not
      // currently supported in one-click signups.
      ...EMPTY_STREET_ADDRESS_FIELDS_FOR_SIGNUP_REQUEST,
      ...trackingParams,
    }
    let resp
    try {
      resp = await api.signUpByEventId(event.id, signupRequest)
    } catch (e) {
      // TODO eventually we should handle error
      setSignupButtonLoading(false)
      return
    }
    setSignupResponse(resp)
    setSignupButtonLoading(false)
    setModalOpen(showSignupModal)
    if (!resp.user_is_blocked) {
      trackRegistrationCreated(signupRequest, organization, event, signupSource)
    }
  }
  const modals = [
    ...(event.post_signup_asks.includes(PostSignupAsk.SOCIAL_SHARING)
      ? [ModalTypes.SHARE]
      : []),
    ...(event.post_signup_asks.includes(PostSignupAsk.EVENT_SUGGESTIONS)
      ? [ModalTypes.SIGNUP, ModalTypes.LINKED_GROUP]
      : []),
  ]

  const shareLink = getOrganizationEventUrl(organization, event, {
    // `undefined` means to exclude this query param if there's no user; note that this would only
    // really happen if we had to move signups to a queue (and so the endpoint couldn't respond
    // with a user id)
    referring_vol: signupResponse?.user_id || undefined,
    rname: signupIdentityFields.firstName,
    timeslot: timeslotId,
  }).toString()
  const nativeShareShortlink = signupResponse?.share_shortlink
    ? getSubtypedShortlink(
        signupResponse.share_shortlink,
        ParticipationShortlinkSubtype.POST_SIGNUP_NATIVE_SHARE
      ).toString()
    : null
  return (
    <>
      {showTurnstile ? (
        <TurnstileWidget action="signup-button" onVerify={attemptSignup} />
      ) : (
        <Button
          success={signedUp}
          icon={signedUp ? 'check' : undefined}
          loading={signupButtonLoading}
          onClick={() => attemptSignup()}
          centered={centered}
          inversePrimaryColors={inversePrimaryColors}
        >
          {/** without this span the text gets centered, which looks weird next to the left-aligned
           * text of the "see details" buttons on the feed (which happens because those have icons).
           * there's probably something we can do within Button to unravel this mess but punt for now
           */}
          <span
            data-testid="signup-button"
            style={{textAlign: centered ? 'center' : 'left', display: 'block'}}
          >
            {signupButtonLabel}
          </span>
        </Button>
      )}
      {!!signupResponse && (
        <ChainingModalStepper
          chainingAction={EventChainingAction.SIGNED_UP}
          event={event}
          identityFields={signupIdentityFields}
          isOpen={modalOpen}
          linkForCopy={signupResponse.share_shortlink}
          linkForNativeShare={nativeShareShortlink}
          modals={modals}
          onRequestClose={() => setModalOpen(false)}
          organization={organization}
          owningGroups={signupResponse.owning_groups || []}
          recommendedTimeslots={signupResponse.recommended_timeslots || []}
          shareLink={shareLink}
          // TODO(jared): this `shareModalType` is incorrect or at best a misnomer (this can appear
          // in places that aren't the feed)
          shareModalType="feed_one_tap_signup"
          trackingParams={trackingParams}
        />
      )}
    </>
  )
}
