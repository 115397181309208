import {
  Button,
  Form,
  Input,
  InputGroup,
  Message,
  MessageType,
  Typography,
} from 'components'
import {omit, pick} from 'util/common'

import {Component} from 'react'
import {F} from 'util/i18n'
import analytics from 'analytics'
import api from 'data/api'
import {connect} from 'react-redux'
import {setCurrentVolunteer} from 'redux/actions'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const mapStateToProps = (state) => {
  return {
    currentVolunteer: state.currentVolunteer,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentVolunteer: (vol) => {
      dispatch(setCurrentVolunteer(vol))
    },
  }
}

const LeadCaptureWrapper = styled.div`
  padding-left: ${styles.space.m};
  padding-right: ${styles.space.m};
  color: ${styles.colors.black};
`

class LeadCaptureForm extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    zip: '',
    error: false,
    errorFields: {},
    success: false,
    submitting: false,
  }

  onChange = (evt) => {
    const {name, value} = evt.currentTarget
    this.setState({[name]: value})
  }

  onSubmit = async (evt) => {
    evt.preventDefault()

    this.setState({
      submitting: true,
      error: false,
      errorFields: {},
    })

    let response
    try {
      response = await api.submitOrgLead(this.props.organization.slug, {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
        zipcode: this.state.zip,
      })
    } catch (error) {
      this.setState({
        error: true,
        errorFields: error.json && omit(error.json.error, 'message'),
        submitting: false,
      })
      return
    }
    const currentVolunteer = pick(
      this.state,
      'firstName',
      'lastName',
      'email',
      'zip'
    )
    this.props.setCurrentVolunteer(currentVolunteer)
    response.user_id &&
      analytics.maybeIdentifyUser({
        id: response.user_id,
        email: response.email,
      })
    analytics.trackLeadCaptured(
      this.props.parentComponent,
      this.props.organization
    )
    if (this.props.onSubmitAction) {
      this.props.onSubmitAction()
    } else {
      this.setState({
        success: true,
      })
    }
  }

  render() {
    return (
      <LeadCaptureWrapper>
        <Typography variant="h3">
          <F defaultMessage="Want to stay in the loop?" />
        </Typography>
        {this.state.success ? (
          <Typography variant="body1">
            <F defaultMessage="Thanks! You'll be notified when new events are available." />
          </Typography>
        ) : (
          <Form onSubmit={this.onSubmit}>
            <Typography variant="body1" component="p">
              <F defaultMessage="Get updates about high priority events." />
            </Typography>
            <InputGroup stackOnMobile>
              <Input
                name="firstName"
                type="text"
                label={<F defaultMessage="First name" />}
                required
                value={this.state.firstName}
                onChange={this.onChange}
                error={!!this.state.errorFields.first_name}
              />
              <Input
                name="lastName"
                type="text"
                label={<F defaultMessage="Last name" />}
                required
                value={this.state.lastName}
                onChange={this.onChange}
                error={!!this.state.errorFields.last_name}
              />
              <Input
                name="email"
                type="email"
                label={<F defaultMessage="Email" />}
                required
                value={this.state.email}
                onChange={this.onChange}
                error={!!this.state.errorFields.email}
              />
              <Input
                isZIPCode
                width="6rem"
                name="zip"
                label={<F defaultMessage="ZIP code" />}
                required
                value={this.state.zip}
                onChange={this.onChange}
                error={!!this.state.errorFields.zipcode}
              />
            </InputGroup>
            {this.state.error && (
              <Message
                type={MessageType.ERROR}
                header={<F defaultMessage="Signup failed." />}
                // Flow thinks Object.values returns mixed even though we know it's
                // Array<string> in this case
                // $FlowFixMe (jared)
                list={Object.values(this.state.errorFields).reduce(
                  (acc, curr) => [...acc, ...curr],
                  []
                )}
              />
            )}
            <Button secondary type="submit" disabled={this.state.submitting}>
              <F defaultMessage="Get Updates" />
            </Button>
          </Form>
        )}
      </LeadCaptureWrapper>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadCaptureForm)
